<template>
    <b-row>
        <b-col cols="12">
            <b-card>
                <b-card-header>
                    <h4>Jumlah dan Harga Post</h4>
                </b-card-header>
                <b-card-body>
                    <a href="javascript:;" @click="back()">Back</a>
                    <hr>
                    <validation-observer
                        ref="step4"
                    >
                        <b-form
                            class="auth-login-form mt-2"
                            @submit.prevent="step4"
                            >
                            <b-row>
                                <b-col class="mb-1" cols="12">
                                    <!-- basic -->
                                    <b-form-group
                                        label="Platform"
                                        label-for="basicInput"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="platform"
                                            vid="select-socmed"
                                            rules="required"
                                            :custom-messages="customMessages"
                                            >
                                            <b-form-select
                                                id="select-socmed"
                                                name="select-socmed"
                                                v-model="selectedSocmed"
                                                :options="optionsSocmed"
                                                :state="errors.length > 0 ? false:null"
                                                />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col class="mb-1" cols="12">
                                    <!-- basic -->
                                    <b-form-group
                                        label="Layanan"
                                        label-for="basicInput"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="layanan"
                                            vid="options-layanan"
                                            rules="required"
                                            :custom-messages="customMessages"
                                            >
                                            <treeselect
                                                id="options-layanan"
                                                name="options-layanan"
                                                v-model="selectedLayanan"
                                                :multiple="true"
                                                :options="optionsLayanan"
                                                :state="errors.length > 0 ? false:null"/>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <template v-for="(ps,i) in populateSelect">
                                    <b-col cols="6" :key="'jml_' + i">
                                        <b-form-group
                                            :label="'Jumlah Post ' + formatLayanan(ps.serviceID, i)"
                                            label-for="basicInput"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                name="post-amount"
                                                vid="post-amount"
                                                rules="required"
                                                :custom-messages="customMessages"
                                                >
                                                <b-input-group>
                                                    <b-input-group-prepend  @click="addPost(i)">
                                                        <b-button variant="outline-primary">
                                                            <feather-icon icon="PlusIcon" />
                                                        </b-button>
                                                    </b-input-group-prepend>
                                                    <b-form-input
                                                        id="post-amount"
                                                        name="post-amount"
                                                        variant="outline-primary"
                                                        v-model="postAmount[i]"
                                                        class="text-center"
                                                        placeholder="Jumlah Post"
                                                        :state="errors.length > 0 ? false:null"/>
                                                    <b-input-group-append>
                                                        <b-button @click="subPost(i)" variant="outline-primary">
                                                            <feather-icon icon="MinusIcon" />
                                                        </b-button>
                                                    </b-input-group-append>
                                                </b-input-group>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                            
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="6" :key="'hrg_' + i">
                                        <b-form-group
                                            label="Harga Per Post"
                                            label-for="basicInput"
                                        >
                                            <b-input-group>
                                                <b-form-input variant="outline-primary" class="text-center" placeholder="Harga Per Post" :value="servicePricingCast(ps.serviceID)" disabled/>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                </template>

                                <b-col cols="4">
                                    <b-form-group
                                        label="Estimasi Budget"
                                        label-for="basicInput"
                                    >
                                        <b-form-input
                                            id="basicInput"
                                            v-model="estimasi_cast"
                                            placeholder="Estimasi Budget"
                                            disabled
                                            />
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12">
                                    <b-form-group
                                        label="Platform Lainnya"
                                        label-for="basicInput"
                                    >
                                        <b-form-input
                                            id="basicInput"
                                            v-model="otherPlatforms"
                                            placeholder="Masukkan info jika kamu memiliki campaign serupa yang tersedia pada platform lain"
                                            />
                                    </b-form-group>
                                </b-col>

                                <b-col class="mt-1" cols="12">
                                    <b-button
                                        type="submit"
                                        block
                                        variant="primary"
                                        >
                                        Selanjutnya
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </validation-observer>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BForm
} from 'bootstrap-vue'
import axios from '@axios'
export default {
    name: 'CampaignCreateStep4',
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BCardHeader,
        BCardBody,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BInputGroup,
        BInputGroupPrepend,
        BInputGroupAppend,
        BForm,

        Treeselect,

        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            selected: null,
            options: [
                { value: null, text: 'Please select an option' },
                { value: 'a', text: 'This is First option' },
                { value: 'b', text: 'Simple Option' },
                { value: { C: '3PO' }, text: 'This is an option with object value' },
                { value: 'd', text: 'This one is disabled', disabled: true },
            ],

            selectedSocmed: null,
            optionsSocmed: [
                { value: null, text: '- Pilih Platform -' },
                { value: 1, text: 'Instagram' },
                { value: 2, text: 'Youtube' },
                { value: 3, text: 'Facebook' }
            ],

            optionsLayanan: [],

            populateSelect: [],
            selectedLayanan: [],
            optionsLayananInstagram: [
                {
                    id: 'instagram=story',
                    label: 'Instagram Story'
                },
                {
                    id: 'instagram=photo',
                    label: 'Instagram Photo'
                },
                {
                    id: 'instagram=video',
                    label: 'Instagram Video'
                },
                {
                    id: 'instagram=tv',
                    label: 'Instagram TV'
                }
            ],
            optionsLayananFacebook: [
                {
                    id: 'facebook=post',
                    label: 'Facebook Post'
                }
            ],
            optionsLayananYoutube: [
                {
                    id: 'youtube=video',
                    label: 'Youtube Video'
                }
            ],
            
            listPricing: [],
            postAmount: [],
            postPricing: [],
            estimasi: null,
            estimasi_cast: null,
            otherPlatforms: null,

            // validation rules
            required,
            email,

            initEdit: (this.$route.name == 'campaign-edit-4'),
            initEditPostAmount: (this.$route.name == 'campaign-edit-4') ||  this.$route.params.forceEdit,
            initEditSelectedLayanan: (this.$route.name == 'campaign-edit-4'),
            initPosting: (this.$route.name == 'campaign-edit-4'),
            customMessages: {
                required: 'Harus diisi'
            }
        }
    },
    async mounted() {
        let _ = this

        if ( _.$route.name == 'campaign-edit-4' ) {
            await _.getCampaignData()
        }

        await _.$nextTick(async () => {
            console.log('nextTick Begin')
            await _.getPricing()
            console.log('nextTick END')
            
            // The whole view is rendered, so I can safely access or query
            // the DOM. ¯\_(ツ)_/¯
        })
    },
    methods: {
        formatLayanan(ly) {
            let _ = this
            let fL = _.optionsLayanan.filter( v => v.id == ly )[0]
            return fL ? fL.label : ''
        },
        step4() {
            let _ = this
            this.$refs.step4.validate().then(success => {
                if (success) {
                    let model = {
                        id:localStorage.getItem('campaignID'),
                        platformTypeID:_.selectedSocmed,
                        otherPlatforms:_.otherPlatforms,
                        services: _.populateSelect.map((ps,i) => {
                            return {
                                id: ps.serviceID,
                                detail: {
                                    postAmount: ps.posting,
                                    cost: ps.pricing
                                }
                            }
                        })
                    }

                    axios.post('/advertiser/campaigns/post-cost', model)
                        .then(resp => {
                            localStorage.setItem('estimasi', this.estimasi)
                            if ( _.$route.name == 'campaign-edit-4' ) {
                                this.$router.push({name: 'campaign-edit-5'})
                            } else {
                                this.$router.push('step-5')
                            }
                        })
                        .catch(err => {
                            console.log(err.response)
                        })
                } else {
                    console.log('failed validation')
                }
            })
        },
        subPost(i) {
            let _ = this
            let newValue = _.postAmount[i]
            if (  newValue > 0 ) {
                newValue = parseInt(_.postAmount[i]) - 1
            } else {
                newValue = 0
            }

            _.$set(_.postAmount, i, newValue)
        },
        addPost(i) {
            let _ = this
            let newValue = 1
            if ( _.postAmount[i] > 0 ) {
                newValue = parseInt(_.postAmount[i]) + 1 
            }

            _.$set(_.postAmount, i, newValue)
        },
        getPricing() {
            let _ = this
            axios.post('/advertiser/campaigns/post-cost/calculate-price', { id: localStorage.getItem('campaignID'), platformTypeID: _.selectedSocmed  })
                .then(resp => {
                    console.log('getPricing()')
                    _.listPricing = resp.data.data

                    return resp.data.data
                })
                .then(() => {
                    _.populateSelect = []
                    _.selectedLayanan.forEach((serviceID, i) => {
                        let servicePricing = _.servicePricing(serviceID)
                        _.populateSelect.push({
                            serviceID: serviceID,
                            pricing: servicePricing
                        })
                    })

                    _.posting()
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        getService() {
            let _ = this
            axios.get('/misc/types/services/' + _.selectedSocmed)
                .then(resp => {
                    let services = resp.data.data.map((v,i) => {
                        return {
                            id: v.id,
                            label: v.name
                        }
                    })
                    _.optionsLayanan = services
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        servicePricingCast(sl) {
            let _ = this
            var pricing = _.listPricing.filter(v => v.serviceID == sl)[0]
            
            return pricing ? pricing.price_cast : 0
        },
        servicePricing(sl) {
            let _ = this
            var pricing = _.listPricing.filter(v => v.serviceID == sl)[0]
            
            return pricing ? pricing.price : 0
        },
        posting() {
            let _ = this
            _.postAmount.forEach((v,i) => {
                if ( _.populateSelect[i] ) {
                    _.populateSelect[i].posting = parseInt(v)
                } else {
                    if ( !_.initPosting ) {
                        _.postAmount[i] = ""
                    }
                }
            })
            
            _.initPosting = false
        },
        estimate() {
            let _ = this
            var estimate = 0
            _.populateSelect.forEach(ps => {
                if ( ps.posting ) {
                    estimate += parseInt(ps.posting) * parseInt(ps.pricing)
                }
            })
            _.estimasi_cast = 'Rp ' + estimate.toLocaleString("id-ID") + ',00'
            _.estimasi = estimate
        },
        async getCampaignData() {
            let _ = this
             await axios.get('/advertiser/campaigns/' + _.$route.params.id)
                .then(resp => {
                    let _ = this
                    let campaign = resp.data.data
                    
                    _.selectedSocmed = campaign.platformType.id
                    
                    _.estimasi = 0
                    let layanan = []
                    let postAmount = []
                    campaign.services.forEach((sv, i) => {
                        layanan.push(sv.id)
                        postAmount.push(sv.detail.postAmount)
                    })
                    _.selectedLayanan = layanan
                    _.postAmount = postAmount
                    _.otherPlatforms = campaign.otherPlatforms
                    // _.populateSelect
                    localStorage.setItem('campaignID', campaign.id)
                    if ( _.$route.name == 'campaign-edit-4' ) {
                        _.estimasi_cast = campaign.cost_cast
                        _.estimasi = campaign.cost
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        back(){
            let _ = this
            let campaignID = localStorage.getItem('campaignID') || _.$route.params.id
            // if ( _.$route.name == 'campaign-edit-4' ) {
            _.$router.push({name: 'campaign-edit-3', params: {id: campaignID }})
            // } else {
                // _.$router.push({name: 'campaign-create-3', params: {id: localStorage.getItem('campaignID')}})
            // }
        }
    },
    watch: {
        selectedSocmed: function(newValue, oldValue) {
            let _ = this
            if ( newValue ) {
                if ( !_.initEdit ) {
                    _.selectedLayanan = []
                }
                _.initEdit = false
                _.getPricing()
                _.getService()
            }
        },
        postAmount: function(newValue, oldValue) {
            let _ = this
            if ( newValue ) {
                _.posting()
                if ( !_.initEditPostAmount ) {
                    _.estimate()
                }
                _.initEditPostAmount = false
            }
        },
        selectedLayanan: function(newValue, oldValue) {          
            let _ = this  
            _.populateSelect = []

            newValue.forEach((serviceID, i) => {
                let servicePricing = _.servicePricing(serviceID)
                _.populateSelect.push({
                    serviceID: serviceID,
                    pricing: servicePricing
                })
            })

            _.posting()
        },
        listPricing: function(newValue, oldValue) {
            let _ = this
            if ( _.initEdit ) {
                _.selectedLayanan.forEach((serviceID, i) => {
                    let servicePricing = _.servicePricing(serviceID)
                    _.populateSelect.push({
                        serviceID: serviceID,
                        pricing: servicePricing
                    })
                })
            }
            _.initEdit = false
        }
    }
}
</script>